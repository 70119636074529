



















































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import api from "@/api";
import uploadNew from "@/components/uploadNew.vue";
import {clientLink, showTagRE} from '@/utils/common'
import {imageUrl} from '@/utils/common'

@Component({
    name: "activityAdd",
    components: {
        uploadNew: uploadNew
    }
})

export default class extends Vue {
    baseUrl = imageUrl
    num = 1
    intervals: any = [
        {
            awardRates: [{awardId: '', awardRate: ''}],
            coverAward: '',
            intervalCode: ''
        }
    ]
    LotteryMode = {
        tur: '幸运大转盘'
    }

    cjList = [
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
    ]

    spType = {
        1:'积分',
    }

    model: any = {
        OSSAccessKeyId: "",
        signature: "",
        policy: "",
        key: "",
        callback: "",
        file: "",
        success_action_status: ''
    };
    fileList: any = {
        image_url: '',
        image_name: ''
    }
    fileList2: any = {
        image_url: '',
        image_name: ''
    }

    zxp(row: any) {
        if(isNaN(row.awardRate)) {
            this.$message.warning('请输入正整数')
            row.awardRate = ''
            return
        }
        if(row.awardRate < 0 || row.awardRate > 100) {
            this.$message.warning('请输入0-100的数字')
            row.awardRate = ''
            return;
        }
        if(row.awardRate.indexOf('.') > -1) {
            this.$message.warning('请输入0-100的数字')
            row.awardRate = ''
            return;
        }
    }

    getData1(item: any) {
        console.log('item1', this.awards[this.key]['imageUrlList']);
        this.awards[this.key]['imageUrl'] = item
        this.awards[this.key]['imageUrlList'] = {
            image_url: item,
            image_name: item
        }
        console.log('item2', this.awards[this.key]['imageUrlList']);
        this.$forceUpdate()
        // this.form.popupImg = item
    }
    getData2(item: any) {
        this.awards[this.key]['drawImageUrl'] = item
        this.awards[this.key]['drawImageUrlList'] = {
            image_url: item,
            image_name: item
        }
        this.$forceUpdate()
        // this.form.bannerImg = item
    }

    key = ''
    choseU(key: any) {
        this.key = key
        console.log(key);
        // this.awards[key].imageUrl=
        // alert(key)
    }

    zxpType(item: any) {
        if(!isNaN(item.awardContent)) {
            if(item.awardContent.indexOf('.') >-1) {
                this.$message.warning('内容不正确')
                item.awardContent = ''
                return
            }
        }
    }

    awards: any = []

    generateRandomString() {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const timestamp = Date.now().toString();
        let randomStr = '';

        // 生成 6 个随机字母
        for (let i = 0; i < 3; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            randomStr += chars[randomIndex];
        }

        // 将时间戳和随机字符串组合
        return randomStr + timestamp;
    }

    addJC() {
        let tmpId = this.generateRandomString()
        console.log('tmpId', tmpId);
        this.awards.push({
            awardContent: '',
            awardCount: '',
            awardName: '',
            awardType: '',
            drawImageUrl: '',
            imageUrl: '',
            tmpId: tmpId,
            imageUrlList: {image_url: '', image_name: ''},
            drawImageUrlList: {image_url: '', image_name: ''},
        })

        this.num = this.num + 1
        console.log('123',this.awards.length, this.num, this.intervals);

        this.intervals.forEach((item: any) => {
            item['awardRates'].push({awardId: '', awardRate: ''})
        })
        // this.intervals[0
    }

    delJC(index: any) {
        this.awards.splice(index, 1)
        this.intervals.forEach((item: any) => {
            item['awardRates'].splice(index, 1)
        })
        this.cjList[index].value = ''
    }

    delJiangChi(index: any) {
        this.intervals.splice(index, 1)
    }

    addJiangChi() {
        let awardRates: any = []
        this.intervals[0].awardRates.forEach((item: any) => {
            awardRates.push({awardId: '', awardRate: ''})
        })
        console.log(this.intervals);
        this.intervals.push({
            awardRates: awardRates,
            coverAward: '',
            intervalCode: ''
        })
    }

    form: any = {
        applyId: ''

    }

    // 标签
    tagVal = ''
    tags: any = []
    tagsC: any = []
    newTags: any = []
    delTags: any = []


    handleClose(tag: any) {
        this.delTags.push(tag)
        this.tags.splice(this.tags.indexOf(tag), 1);

    }


    sureLoading = false
    sure() {

        let gameJson: any = []
        // if(this.cjList.length) {}
        let kongzhiList = []
        this.cjList.forEach(i=> {
            if(i.value === '') {
                kongzhiList.push(i)
                return
            }
            if(i.value) {
                gameJson.push(i.value)
            }
        })
        if(kongzhiList.length > 0) {
            this.$message.warning('转盘号位必填')
            return;
        }

        this.form.intervals = this.intervals
        this.form.awards = this.awards
        this.form.gameJson = JSON.stringify(gameJson)

        this.form.applyId = this.$route.query.lotteryId

        this.form.awards.forEach((item: any) => {
            if(item.awardContent.indexOf('免单') > -1) {
                item.awardContent = 'free'
            }
        })
        console.log('ccccc', this.form);

        this.sureLoading = true
        api
            .post(this.isAdd ? 'J51101' : 'J51103', this.form)
            .then(res =>{
                this.tags = []
                this.newTags = []
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
                // if(this.$route.query.id) {
                //     this.getDetail(this.$route.query.id)
                // }
                this.$router.push({
                    name: 'applet_activity_prize'
                })
            }).finally(() => {
                this.sureLoading = false
        })

    }


    // 编辑
    isAdd = false
    getDetail(id: any) {
        api
            .post('J51102', {id: this.id})
            .then(async (res: any) => {
                console.log('123123', res);
                if(!res) {
                    // 没数据表示还没新建
                    this.isAdd = true
                    // 抽奖商品
                    let str = this.generateRandomString()
                    this.awards = [
                        {
                            awardContent: '',
                            awardCount: '',
                            awardName: '',
                            awardType: '',
                            drawImageUrl: '',
                            tmpId: str,
                            imageUrlList: {image_url: '', image_name: ''},
                            drawImageUrlList: {image_url: '', image_name: ''},
                        }
                    ]
                    // 抽奖奖池
                    this.intervals = [
                        {
                            awardRates: [{awardId: '', awardRate: ''}],
                            coverAward: '',
                            intervalCode: ''
                        }
                    ]

                    return
                }

                this.form = res

                this.form.gameJson = JSON.parse(res.gameJson)
                if(this.form.gameJson) {
                    this.form.gameJson.forEach((i: any,k: any) => {
                        this.cjList[k].value = i
                    })
                }

                this.awards = this.form.awards
                this.awards.forEach((item: any) => {
                    item.imageUrlList = {
                        image_url: item.imageUrl,
                        image_name: item.imageUrl
                    }
                    item.drawImageUrlList = {
                        image_url: item.drawImageUrl,
                        image_name: item.drawImageUrl
                    }
                    if(item.awardContent == 'free') {
                        item.awardContent = '免单'
                    }
                })
                this.intervals = this.form.intervals
                console.log('this.awards', this.awards);


            })
    }

    id: any = ''
    showUpload = true
    activated() {
        this.form = {}

        this.intervals = [
            {
                awardRates: [{awardId: '', awardRate: ''}],
                coverAward: '',
                intervalCode: ''
            }
        ]
        this.cjList = [
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
            {value: ''},
        ]

        this.id = this.$route.query.lotteryId
        this.form.applyId = this.$route.query.lotteryId
        this.form.lotteryName = this.$route.query.name
        if(this.id) {
            this.getDetail(this.id)
        }

    }


}
